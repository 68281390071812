<template>

  <div>

    <b-modal
      id="import-modal"
      ref="import-modal"
      title="Excel'den E Posta Adresi Aktar"
      :ok-title="$t('İçe Aktarmayı Başlat')"
      :cancel-title="$t('Vazgeç')"
      :hide-footer="hideModalFooter"
      @show="resetImportModal"
      @hidden="resetImportModal"
      @ok="startImport"
    >
      <b-overlay
        :show="importModalOverlayShow"
        rounded="sm"
      >
        <b-alert
          variant="primary"
          show
        >
          <div class="alert-body">
            <span>Excel dosyasında ilk sütuna e posta adresleri sıralanmalıdır.</span>
          </div>
        </b-alert>
        <b-form-group
          :label="$t('Excel Dosyası')"
          label-for="image_file_path"
        >
          <b-form-file
            id="image_file_path"
            accept=".xls, .xlsx"
            :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
            :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
            @change="onFileChange"
          />

        </b-form-group>
        <span v-if="excelImportRows"><strong>{{ excelImportRows.length }}</strong> Adet Kayıt Bulundu</span>
      </b-overlay>
    </b-modal>
    <customer-list-add-new
      :is-add-new-customer-sidebar-active.sync="isAddNewCustomerSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
      @addIsSuccess="addIsSuccess"
    />

    <!-- Filters -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Gösterilen') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Kayıt') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Firma Adı Arayın..."
              />
              <b-button
                v-if="$can('read', 'customer_edit')"
                variant="primary"
                @click="isAddNewCustomerSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('Firma Ekle') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0 "
          >

            <b-button
              v-if="$can('read', 'customer_edit')"
              id="import-modal-toggle-btn"
              v-b-modal.import-modal
              variant="dark"
              size="sm"
            >
              <feather-icon
                icon="UploadIcon"
                class="mr-50"
              />
              <span class="text-nowrap">{{ $t("Excel'den İçer Aktar") }}</span>
            </b-button>

          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCustomerListTable"
        class="position-relative"
        :items="fetchCustomers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        small
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveCustomerRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>

          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveCustomerRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveCustomerRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(managers)="data">
          <app-collapse>

            <app-collapse-item :title="$t('Firma Yetkilileri')">
              <b-list-group>

                <b-list-group-item
                  v-for="(item, index) in data.item.managers"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span><strong>{{ item.title_name }} : </strong>{{ item.name+" "+item.surname }} | {{ item.email }} | {{ item.phone }}</span>

                </b-list-group-item>
              </b-list-group>
            </app-collapse-item>
          </app-collapse>
        </template>
        <template #cell(status_infos)="data">
          <b-badge
            v-if="data.item.is_black_list==1"
            variant="danger"
            pill
            class="badge-round"
          >
            {{ $t('Kara Listede') }}
          </b-badge>
        </template>
        <template #cell(creator)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-badge
                v-if="data.item.creator==1"
                variant="primary"
                pill
                class="badge-round"
              >
                {{ $t('Manuel Oluşturulan') }}
              </b-badge>
              <b-badge
                v-if="data.item.creator!=1"
                variant="warning"
                pill
                class="badge-round"
              >
                {{ $t('Otomatik Oluşturulan') }}
              </b-badge>
            </template>

          </b-media>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'customers-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Düzenle') }}</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Gösterilen') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Toplam Kayıt') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCustomers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BListGroup, BListGroupItem, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import readXlsxFile from 'read-excel-file'
import useCustomersList from './useCustomerList'
import customerStoreModule from '../customerStoreModule'
import CustomerListAddNew from './CustomerListAddNew.vue'

export default {
  components: {
    CustomerListAddNew,
    BCard,
    BRow,
    BListGroup,
    AppCollapse,
    BListGroupItem,
    AppCollapseItem,
    BFormFile,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      importModalOverlayShow: false,
      excelImportRows: null,
    }
  },
  computed: {
    hideModalFooter() {
      if (this.excelImportRows == null) {
        return true
      }
      if (this.excelImportRows.length <= 0) {
        return true
      }
      return false
    },
  },
  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    resetImportModal() {
      this.excelImportRows = null
    },
    startImport(bvModalEvt) {
      this.importModalOverlayShow = true
      bvModalEvt.preventDefault()
      store.dispatch('app-customer/addCustomersBulk', { data_arr: this.excelImportRows })
        .then(() => {
          this.$nextTick(() => {
            this.$refs['import-modal'].toggle('#import-modal-toggle-btn')
          })
          this.showSuccessMessage()
          this.importModalOverlayShow = false
        }).catch(() => {
          this.importModalOverlayShow = false
          this.showErrorMessage()
        })

      // Prevent modal from closing

      // Trigger submit handler
    },
    onFileChange(event) {
      const xlsxfile = event.target.files ? event.target.files[0] : null
      this.excelImportRows = []
      readXlsxFile(xlsxfile).then(rows => {
        let counter = 0
        rows.forEach(val => {
          // console.log(val)
          // eslint-disable-next-line no-plusplus
          counter++
          if (counter >= 3) {
            const importData = {
              user_uyum_code: val[0],
              customer_name: val[1],
              web_site: val[2],
              city: val[3],
              address: val[4],
              manager_title: val[5],
              manager_name: val[6],
              manager_surname: val[7],
              manager_phone: val[8],
              manager_internal_phone: val[9],
              manager_phone2: val[10],
              manager_mobile_phone: val[11],
              manager_email: val[12],
              manager_email2: val[13],
              is_bulk_mail: val[14],
              is_b2b: val[15],

            }

            this.excelImportRows.push(importData)
          }
        })
      })
    },
    addIsSuccess(id) {
      this.editCustomerId = id
      this.isEditCustomerSidebarActive = true
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const editCustomerId = ref(0)
    const isAddNewCustomerSidebarActive = ref(false)
    const isEditCustomerSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,

      // UI

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useCustomersList()

    return {

      // Sidebar
      isAddNewCustomerSidebarActive,
      isEditCustomerSidebarActive,
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      editCustomerId,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,

      // Filter
      avatarText,

      // UI

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
